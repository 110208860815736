import React from "react"
const Footer=()=>
{
return(      <div>
    <footer className="bg-dark">
<div className="row">
<div className="col-4"></div>
<div className="col-6">

<div className="footerContent">
        -
          <br />
          <h2 className="chgcol onscreen">
    Designed &#38; Built by Omar Kanaan
          </h2>
</div>
</div>
<div className="col-4"></div>
</div>

    </footer>
  </div>)
}
export default Footer 