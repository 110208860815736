import React from "react"
const Projects=()=>
{
return(        <div id="projects" className="row">
<div className="col"></div>
<div className="col-lg-12 col-sm-12">
  <div className="jumbotron jumbotron-fluid eliminate onsmall">
    <div className="container">
      <h2
        className="display-4 my-4 welcoming borderr"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        Projects
      </h2>
      <p
        style={{position:"relative"}}
        className="lead paragrapgh img-fluid"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
      <div className="chatfeature" style={{backgroundColor:"crimson",height:30,position:"absolute",color:"#fff",fontSize:15,fontWeight:"bold",padding:5,transform:"rotate(-30deg)",top:-20}}>Chat Feature</div>
        <a href="https://sellsphere.online">
          <img width="190px" src="buyandsell.png" alt="buyandsell-website"/>
        </a>
      </p>
      <p
        className="lead paragrapgh img-fluid"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <a href="https://nextgig.digital/">

          <img width="190px" src="jobs-website.jpeg" alt="jobs-website"/>
        </a>
      </p>
      <p
        className="lead paragrapgh img-fluid"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <a href="https://omartapes.site/">

          <img width="190px" src="omartapes.jpeg" alt="omartapes"/>
        </a>
      </p>

      <p
        className="lead paragrapgh img-fluid"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <a href="https://omar-qr.netlify.app/">

          <img src="qr.jpeg" alt="qr-project"/>
        </a>
      </p>
      <p
        className="lead paragrapgh img-fluid"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <a href="https://omar-weather.netlify.app/">
        
          <img src="weather.jpeg" alt="weather"/>
        </a>
      </p>
      <p
        className="lead paragrapgh img-fluid"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
                      
        <a href="https://computerrepair.netlify.app/">
          
          <img src="project2.jpeg" alt="project_2"/>
        </a>
      </p>
    </div>
  </div>
</div>
<div className="col-sm-1"></div>
</div>)
}
export default Projects