import React from "react"
const Education=()=>
{
return(      <div id="education" className="col-lg-12 col-sm-12">
<div className="jumbotron jumbotron-fluid eliminate onsmall">
  <div className="container">
    <h2
      className="display-4 my-4 welcoming borderr"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      Education
    </h2>
    <p
      className="lead paragrapgh UJHGYUkkujLIUHkjhj"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      University: Jordan University of Science and Technology
    </p>
    <p
      className="lead paragrapgh UJHGYUkkujLIUHkjhj"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      Major: Computer Information Systems
    </p>
    <p
      className="lead paragrapgh UJHGYUkkujLIUHkjhj"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      Degree: Bachelor's degree
    </p>
    <p
      className="lead paragrapgh UJHGYUkkujLIUHkjhj" 
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      GPA: 3.59
    </p>
    <p
      className="lead paragrapgh UJHGYUkkujLIUHkjhj"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      Year: 2019
    </p>
  </div>
</div>
</div>)
}
export default Education